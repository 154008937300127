import styled from 'styled-components';
import Hero from '@/assets/affiliate-hero.jpg';

import { ContentWrapper } from '../HeroSection/style';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';

export const AffiliateContainer = styled(SectionContainer)`
  background: url(${Hero}) no-repeat;
  padding: 1rem 0 5rem;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 5vh;
  }
`;

export const Wrapper = styled(ContentWrapper)`
  padding-top: 4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 3vh;
    padding: 0 20rem 0rem 10rem;

    h1 {
      margin-left: 2rem;
    }
  }
`;

export const ObjetiveWrapper = styled(ContentWrapper)`
  align-items: flex-start;
  gap: 1.5rem;

  p {
    line-height: 1.5rem;
    margin-left: 2rem;
  }

  .highlight {
    border: solid 1px ${({ theme }) => theme.colors.primary};
    border-radius: 2rem;
    margin-left: 2px;
    padding: 0.8rem 1.5rem;
    width: auto;

    p {
      margin: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 16rem 2rem 10rem;
  }
`;
