import React from 'react';

import i18n from '@/translate/i18n';
import {
  ExternalLink,
  HashLink,
  NavLink,
} from '@/components/atoms/Typography/styles';

import { Container, LogoVpag } from './styles';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';

function NavLinks() {
  return (
    <Container>
      <ExternalLink href="https://vpag.com" target="_blank">
        <LogoVpag />
      </ExternalLink>
      <NavLink to="/about-us">{i18n.t('header.about')}</NavLink>
      <HashLink smooth to="/#brands">
        {i18n.t('header.brands')}
      </HashLink>
      <HashLink smooth to="/#clients">
        {i18n.t('header.clients')}
      </HashLink>

      <HashLink smooth to="/#contact">
        <ContactButton>{i18n.t('header.contact')}</ContactButton>
      </HashLink>
    </Container>
  );
}

export default NavLinks;
