import styled from 'styled-components';

export const SectionContainer = styled.section`
  align-items: center;
  background-color: ${({ theme, color = 'shadow' }) => theme.colors[color]};
  display: flex;
  flex-direction: column;
  gap: 1vh;
  min-height: 100vh;
  padding: 3rem 0 8rem;
  position: relative;
  width: 100%;

  h1 {
    margin: 0.67em 0;
  }

  .flex-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .wrapper {
    width: 90vw;
  }

  .highlight-text {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .highlight {
    border: solid 2px ${({ theme }) => theme.colors.primary};
    border-radius: 2rem;
    margin: 2rem 0;
    padding: 0.8rem 1.5rem;
    width: auto;

    p {
      margin: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 0vh;
    padding: 3rem 0 6rem;

    .highlight {
      margin: 1rem 0 1rem -2rem;
    }

    .wrapper {
      width: 48vw;
      max-width: 670px;
    }
  }
`;
