import React from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import data from './data';
import { Container, ContentWrapper, MerchantWrapper } from './styles';

function OurClients() {
  return (
    <Container id="clients">
      <ContentWrapper>
        <Text size="4xl" weight="700" color="primary">
          {i18n.t('ourClients.whoTrustUs')}
        </Text>
        <Text />
        <MerchantWrapper>
          {data.map(({ slug, image }) => (
            <img src={image} alt="slug" key={slug} />
          ))}
        </MerchantWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default OurClients;
