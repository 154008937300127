export default [
  {
    title: 'SBC Summit Rio',
    schedule: '25 - 27 February 2025',
    location: 'Rio de Janeiro, Brazil',
    image: '/images/events/sbc-summit-rio.png',
    url: '/#contact',
  },
  {
    title: 'Big Sigma Americas',
    schedule: '07 - 10 April 2025',
    location: 'São Paulo, Brazil',
    image: '/images/events/bis-sigma-americas.png',
    url: '/#contact',
  },
];
