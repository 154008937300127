import React from 'react';

import ExternalLinkButton from '@/components/atoms/Button/ExternalLinkButton';
import i18n from '@/translate/i18n';
import image from '@/assets/services.png';
import { ReactComponent as VpagLogo } from '@/assets/vpag-logo-black.svg';
import { Text } from '@/components/atoms/Typography/styles';

import { Container, ContentWrapper, SectionImage, TextWrapper } from './styles';

import 'swiper/css';
import 'swiper/css/pagination';

function VpagSection() {
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <VpagLogo />
          <Text
            size="4xl"
            weight="500"
            variant="tertiary"
            className="section-title"
            lineHeight="45px"
          >
            {i18n.t('vpagSection.title')}
          </Text>
          <Text variant="secondary" size="md">
            {i18n.t('vpagSection.description')}
          </Text>
        </TextWrapper>
        <SectionImage src={image} alt="Vpag Wallet and Vpag Stores" />
      </ContentWrapper>
      <ExternalLinkButton
        target="_blank"
        url="https://vpag.com"
        label={i18n.t('vpagSection.getToKnow')}
        className="absolute-btn"
      />
    </Container>
  );
}

export default VpagSection;
