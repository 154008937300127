import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.primary};
  min-height: 55vh;
  padding: 3rem 0 10rem;
  position: relative;
  display: none;

  .absolute-btn {
    bottom: 10%;
    position: absolute;
    z-index: 9;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 6rem 0 10rem;
    display: flex;

    .absolute-btn {
      bottom: 8%;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    gap: 2rem;
    height: 100%;
    justify-content: space-between;
    text-align: left;
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  svg {
    margin-bottom: 10px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    width: 28%;

    .section-title {
      min-width: 440px;
    }
  }
`;

export const SectionImage = styled.img`
  max-width: 550px;
  width: 100%;
`;
