import React from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import influencersImage from '@/assets/influencers-square.webp';

import {
  Container,
  ContentWrapper,
  DesktopImage,
  Item,
  ItemsContainer,
  ItemsWrapper,
  MobileImage,
  TitleImageDesktop,
  TitleImageMobile,
  Wrapper,
} from './styles';

function InfluencersSection() {
  return (
    <Container>
      <ContentWrapper>
        <Wrapper>
          <div>
            <Text size="4xl" weight="700" color="light">
              {i18n.t('ourInfluencers.title')}
            </Text>
            <MobileImage src={influencersImage} alt="" />
            <Text size="lg" color="light">
              {i18n.t('ourInfluencers.content')}
            </Text>
          </div>
          <DesktopImage src={influencersImage} alt="" />
        </Wrapper>

        <ItemsWrapper>
          <TitleImageDesktop />
          <TitleImageMobile />
          <ItemsContainer>
            {i18n
              .t('ourInfluencers.data', {
                returnObjects: true,
              })
              .map(({ title, content }) => (
                <Item key={title}>
                  <Text size="4xl" weight="400" color="light">
                    {title}
                  </Text>
                  <Text size="md" color="light">
                    {content}
                  </Text>
                </Item>
              ))}
          </ItemsContainer>
        </ItemsWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default InfluencersSection;
