import React from 'react';

import { Text } from '@/components/atoms/Typography/styles';

import {
  Card,
  CardContainer,
  Container,
  ContentWrapper,
  TextWrapper,
} from './styles';
import i18n from '@/translate/i18n';

function HowWeDoIt() {
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <Text size="5xl" weight="700" color="primary">
            {i18n.t('howWeDoIt.title')}
          </Text>
          <div>
            <Text size="2xl" weight="700" color="light">
              {i18n.t('howWeDoIt.description')}
            </Text>
            <Text size="lg" color="light">
              {i18n.t('howWeDoIt.content')}
            </Text>
          </div>
        </TextWrapper>
      </ContentWrapper>
      <CardContainer>
        {i18n
          .t('howWeDoIt.data', {
            returnObjects: true,
          })
          .map(({ title, image }) => (
            <Card key={title}>
              <img src={image} alt={title} />
              <p>{title}</p>
            </Card>
          ))}
      </CardContainer>
    </Container>
  );
}

export default HowWeDoIt;
