import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  min-height: 55vh;
  padding: 4rem 0;
  position: relative;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
`;

export const MerchantWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.light};
  border-radius: 30px;
  display: flex;
  flex-flow: row wrap;
  gap: 5rem;
  justify-content: center;
  padding: 3rem 0;
  width: 90%;

  img {
    width: 200px;
    height: 100px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 3rem;
    gap: 2rem;

    img {
      width: 180px;
    }
  }
`;
