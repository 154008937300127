import React from 'react';

import ArticleHeader from '@/components/molecules/ArticleHeader';
import ExternalLinkButton from '@/components/atoms/Button/ExternalLinkButton';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { Container } from '@/components/molecules/Article/styles';
import { Text } from '@/components/atoms/Typography/styles';

import { ContactWrapper, ContentWrapper, TextWrapper } from './styles';

function AboutUs() {
  return (
    <>
      <Header />
      <Container>
        <ArticleHeader title={i18n.t('aboutUs.title')} />
        <ContentWrapper>
          <TextWrapper>
            <Text size="3xl" weight="700" color="primary">
              {i18n.t('aboutUs.contentTitle')}
            </Text>
            <Text size="lg">{i18n.t('aboutUs.contentDescription')}</Text>
          </TextWrapper>

          <ContactWrapper>
            <Text size="3xl" weight="700">
              {i18n.t('aboutUs.contactUs')}
            </Text>
            <ExternalLinkButton
              isHashLink
              target="_self"
              url="/#contact"
              label={i18n.t('aboutUs.contactUsNow')}
            />
          </ContactWrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default AboutUs;
