import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  min-height: 55vh;
  padding: 3rem 0 5rem;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 6rem 0 8rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 970px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 2rem;
    text-align: left;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 3rem;
  margin-bottom: 4rem;

  > p {
    font-size: 32px !important;
  }

  & div > p {
    font-size: 20px !important;
  }

  & > div p:last-child {
    font-size: 14px !important;
  }

  div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding-top: 15px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    padding: 0;

    > p {
      font-size: 92px !important;
      max-width: 420px;
    }

    & div > p {
      font-size: 30px !important;
    }

    & > div p:last-child {
      font-size: 20px !important;
    }

    div {
      width: 45%;
      padding-top: 20px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
  justify-content: center;
`;

export const Card = styled.article`
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  gap: 2rem;
  width: 60%;
  height: 50px;
  padding-inline: 20px;
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: center;
  text-align: left;

  img {
    width: 20px;
    object-fit: scale-down !important;
  }

  p {
    font-size: 10px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 70px;
    width: 300px;

    p {
      font-size: medium;
    }

    img {
      width: auto;
    }
  }
`;
